import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faHome,
    faAddressCard,
    faEnvelope,
    faFolderOpen,
    faAngleDown,
    faAngleUp,
    faBars,
    faServer,
    faMobileAlt,
    faDesktop,
    faWrench,
    faDatabase,
    faAngleRight,
    faCode,
    faBug,
    faBriefcase,
    faGraduationCap,
    faKeyboard,
    faCertificate,
    faBook,
    faUser,
    faExclamationCircle,
    faFilePdf,
    faTimes,
    faPencilAlt,
    faBoxOpen,
    faPercent,
    faFileInvoiceDollar,
    faCheck,
    faStar,
    faBuilding,
    faPaperPlane,
    faArchive,
    faClock,
    faShieldAlt,
    faLayerGroup,
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faStar as farStar, faStarHalfAlt } from '@fortawesome/free-regular-svg-icons';

library.add(
    faHome,
    faAddressCard,
    faEnvelope,
    faFolderOpen,
    faLinkedin,
    faGithub,
    faAngleDown,
    faAngleUp,
    faBars,
    faServer,
    faMobileAlt,
    faDesktop,
    faWrench,
    faDatabase,
    faAngleRight,
    faCode,
    faBug,
    faBriefcase,
    faGraduationCap,
    faKeyboard,
    faCertificate,
    faBook,
    faUser,
    faExclamationCircle,
    faFilePdf,
    faTimes,
    faPencilAlt,
    faBoxOpen,
    faPercent,
    faFileInvoiceDollar,
    faCheck,
    faStar,
    farStar,
    faStarHalfAlt,
    faBuilding,
    faPaperPlane,
    faArchive,
    faClock,
    faShieldAlt,
    faLayerGroup,
);
